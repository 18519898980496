function createObject() {
  let example = {
    id: "LBST_Gruppen_Fehler",
    showGroupNames: false,
    checkAfterHowManyItems: 3,
    checkAfterExactItemCount: true,
    workOrder:
      "Die folgenden Kärtchen zeigen Fehler bei der schriftlichen Addition. Versuchen Sie den Fehler nachzuvollziehen. Ziehen Sie je drei ähnliche Fehler in eine Gruppe.",
    possibleCategories: [
      {
        id: 1,
        name: "Fehler mit der Null",
      },
      {
        id: 2,
        name: "Fehler mit unterschiedlichen Stellenzahlen",
      },
      {
        id: 3,
        name: "Fehler beim Übertrag",
      },
      {
        id: 4,
        name: "Fehler beim Vorgehen",
      },
    ],
    elements: [
      {
        id: 1,
        image: require("@/assets/zr/Gruppen_Fehler/Element1_Fehler_Ziffernrechnen.png"),
        category: 2,
        flex: 2,
      },
      {
        id: 2,
        image: require("@/assets/zr/Gruppen_Fehler/Element2_Fehler_Ziffernrechnen.png"),
        category: 3,
        flex: 2,
      },
      {
        id: 3,
        image: require("@/assets/zr/Gruppen_Fehler/Element3_Fehler_Ziffernrechnen.png"),
        category: 1,
        flex: 2,
      },
      {
        id: 4,
        image: require("@/assets/zr/Gruppen_Fehler/Element4_Fehler_Ziffernrechnen.png"),
        category: 3,
        flex: 2,
      },
      {
        id: 5,
        image: require("@/assets/zr/Gruppen_Fehler/Element5_Fehler_Ziffernrechnen.png"),
        category: 4,
        flex: 2,
      },
      {
        id: 6,
        image: require("@/assets/zr/Gruppen_Fehler/Element6_Fehler_Ziffernrechnen.png"),
        category: 1,
        flex: 2,
      },
      {
        id: 7,
        image: require("@/assets/zr/Gruppen_Fehler/Element7_Fehler_Ziffernrechnen.png"),
        category: 2,
        flex: 2,
      },
      {
        id: 8,
        image: require("@/assets/zr/Gruppen_Fehler/Element8_Fehler_Ziffernrechnen.png"),
        category: 4,
        flex: 2,
      },
      {
        id: 9,
        image: require("@/assets/zr/Gruppen_Fehler/Element9_Fehler_Ziffernrechnen.png"),
        category: 4,
        flex: 2,
      },
      {
        id: 10,
        image: require("@/assets/zr/Gruppen_Fehler/Element10_Fehler_Ziffernrechnen.png"),
        category: 1,
        flex: 2,
      },
      {
        id: 11,
        image: require("@/assets/zr/Gruppen_Fehler/Element11_Fehler_Ziffernrechnen.png"),
        category: 3,
        flex: 2,
      },
      {
        id: 12,
        image: require("@/assets/zr/Gruppen_Fehler/Element12_Fehler_Ziffernrechnen.png"),
        category: 2,
        flex: 2,
      },
    ],
  };

  const zweiFehlerNull =
    "Zwei der Rechnungen weisen einen Fehler auf, bei dem die Null falsch verrechnet wurde. ";

  const zweiFehlerStellen =
    "Zwei der Rechnungen weisen einen Fehler auf, bei dem die Stellenwerte nicht beachtet wurden. ";

  const zweiFehlerUebertrag =
    "Zwei der Rechnungen weisen einen Fehler auf, bei dem der Übertrag nicht richtig angewendet wurde. ";

  const zweiFehlerVorgehen =
    "Zwei Rechnungen weisen einen Fehler auf, bei dem das Verfahren der schriftlichen Addition nicht korrekt angewendet wurde. ";

  const einFehlerNull =
    "Eine Rechnung weist einen Fehler auf, bei dem die Null falsch verrechnet wurde. ";

  const einFehlerStellen =
    "Eine Rechnung weist einen Fehler auf, bei dem die Stellenwerte nicht beachtet wurden. ";

  const einFehlerUebertrag =
    "Eine Rechnung weist einen Fehler auf, bei dem der Übertrag nicht richtig angewendet wurde. ";

  const einFehlerVorgehen =
    "Eine Rechnung weist einen Fehler auf, bei dem das Verfahren der schriftlichen Addition nicht korrekt angewendet wurde. ";

  const erneut = "Welcher Fehler passt nicht? Versuchen Sie es erneut! ";

  const durcheinander =
    "Upps. Das ist noch sehr durcheinander. Welche Rechnungen deuten auf Probleme mit der Null hin? Gibt es Fehler aufgrund von unterschiedlichen Stellenwerten? Bei welchen Rechnungen wurde der Übertrag falsch eingetragen? Wo wurde das Verfahren der schriftlichen Addition falsch angewendet? Versuchen Sie es erneut! ";

  example.responses = [
    {
      id: "3000",
      category: example.possibleCategories[0],
      text:
        "Sehr gut! Diese Rechnungen weisen alle Fehler mit der Null auf. Diese Fehler können bei konsequentem Auftreten durchaus auf Unsicherheiten im Einspluseins hindeuten. Allerdings können hier keine Rückschlüsse auf das Algorithmusverständnis zur schriftlichen Addition gezogen werden, da zunächst die Unsicherheit mit der Null überwunden werden muss.",
    },
    {
      id: "0300",
      category: example.possibleCategories[1],
      text:
        "Richtig. Diese Rechnungen weisen alle Fehler aufgrund von unterschiedlichen Stellenwerten auf. Die Zahlen wurden nicht stellengerecht untereinander geschrieben und so die Ziffern nicht zum passenden Stellenwert der Ziffern der anderen Zahl addiert.",
    },
    {
      id: "0030",
      category: example.possibleCategories[2],
      text:
        "Super. In diesen Rechnungen wurde der Übertrag falsch vermerkt oder nicht bedacht. In Zusammenhang mit dem Übertrag gibt es bei der schriftlichen Addition mehrere Fehlertypen. Diese wurde hier zusammengefasst. Zum einen kann der Übertrag an der falschen Stelle eingetragen , in der Rechnung nicht mitgerechnet oder gar nicht eingetragen werden. Bei Fehlern mit dem Übertrag können Probleme beim verständigen Ausführen des Algorithmus Ursache sein.",
    },
    {
      id: "0003",
      category: example.possibleCategories[3],
      text:
        "Klasse. Bei diesen Rechnungen wurde von links nach rechts gerechnet und somit das Verfahren der schriftlichen Addition falsch angewendet. Dadurch wurde der Übertrag an den falschen Stellen eingetragen. Es wird deutlich, dass bei diesen Fehlern der Algorithmus der schriftlichen Addition noch nicht verstanden, ggf. lediglich automatisiert und falsch abgerufen wurde. ",
    },
    {
      id: "0012",
      text: einFehlerUebertrag + zweiFehlerVorgehen + erneut,
    },
    {
      id: "0021",
      text: zweiFehlerUebertrag + einFehlerVorgehen + erneut,
    },
    {
      id: "0102",
      text: einFehlerStellen + zweiFehlerUebertrag + erneut,
    },
    {
      id: "0111",
      text: durcheinander,
    },
    {
      id: "0120",
      text: einFehlerStellen + zweiFehlerUebertrag + erneut,
    },
    {
      id: "0201",
      text: zweiFehlerStellen + einFehlerVorgehen + erneut,
    },
    {
      id: "0210",
      text: zweiFehlerStellen + einFehlerUebertrag + erneut,
    },
    {
      id: "1002",
      text: einFehlerNull + zweiFehlerVorgehen + erneut,
    },
    {
      id: "1011",
      text: durcheinander,
    },
    {
      id: "1020",
      text: einFehlerNull + zweiFehlerUebertrag + erneut,
    },
    {
      id: "1101",
      text: durcheinander,
    },
    {
      id: "1110",
      text: durcheinander,
    },
    {
      id: "1200",
      text: einFehlerNull + zweiFehlerStellen + erneut,
    },
    {
      id: "2001",
      text: zweiFehlerNull + einFehlerVorgehen + erneut,
    },
    {
      id: "2010",
      text: zweiFehlerNull + einFehlerUebertrag + erneut,
    },
    {
      id: "2100",
      text: zweiFehlerNull + einFehlerStellen + erneut,
    },
  ];

  return example;
}

export default createObject();
