<template>
  <div>
    <h2 class="faledia-caption">Diagnose & Förderung</h2>
    <p>
      Im Hintergrundteil haben Sie erfahren, dass ein sicheres Ausführen eines
      schriftlichen Algorithmus nicht zwangsläufig gleichbedeutend mit einem
      verständigen Ausführen ist. Häufig bleiben grundlegende
      Verständnisschwierigkeiten daher unerkannt, wenn lediglich auf ein
      korrektes Vorgehen und fehlerfreies Ergebnis geblickt wird. Auch wenn der
      Algorithmus der schriftlichen Addition grundsätzlich eher wenig
      fehleranfällig ist, gibt es doch eine Auswahl an typischen Fehlern, die
      durchaus – bei wiederholendem Auftreten – als Indikatoren für ein
      fehlendes oder mangelndes Verständnis gelten können (Gerster, 2015, S. 28;
      Fast, 2017, S. 57). „Eine Kenntnis wichtiger Fehlerstrategien beim
      schriftlichen Rechnen durch die Lehrkräfte ist unbedingt erforderlich“
      (Padberg & Benz, 2021, S. 248), da nur in diesem Fall präventiv aber auch
      bei Bedarf rückwirkend mit entsprechenden Fördermaßnahmen einem
      verständnislosen und fehlerhaften Vorgehen entgegengewirkt werden kann
      (Padberg & Benz, 2021, S. 248). Im Folgenden sind Sie daher aufgefordert,
      Lernendenlösungen nach ähnlichen Vorgehensweisen und Fehlern zu
      gruppieren.
    </p>
    <div style="background-color: white">
      <LernbausteinGruppen
        :id="LBSTGruppen.id"
        :checkAfterHowManyItems="LBSTGruppen.checkAfterHowManyItems"
        :checkAfterExactItemCount="LBSTGruppen.checkAfterExactItemCount"
        :showGroupNames="LBSTGruppen.showGroupNames"
        :workOrder="LBSTGruppen.workOrder"
        :possibleCategories="LBSTGruppen.possibleCategories"
        :elements="LBSTGruppen.elements"
        :responses="LBSTGruppen.responses"
      >
        <template v-slot:alternative-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Fehlertyp</td>
                  <td>Beispiel</td>
                  <td>Beschreibung</td>
                </tr>
                <tr>
                  <td>Fehler mit der Null</td>
                  <td>
                    <div class="imageGruppe">
                      <AppExpandableImage
                        contain
                        :src="
                          require('@/assets/zr/Gruppen_Fehler/Element6_Fehler_Ziffernrechnen.png')
                        "
                        parentId="zr"
                        elementId="zr_Gruppe_Fehler_6.png"
                      >
                      </AppExpandableImage>
                    </div>
                  </td>
                  <td>
                    Diese Fehler können bei konsequentem Auftreten durchaus auf
                    Unsicherheiten im Einspluseins hindeuten. Allerdings können
                    hier keine Rückschlüsse auf das Algorithmusverständnis zur
                    schriftlichen Addition gezogen werden, da zunächst die
                    Unsicherheit mit der Null überwunden werden muss.
                  </td>
                </tr>
                <tr>
                  <td>
                    Fehler durch unterschiedliche Stellenzahl (leere Stellen)
                  </td>
                  <td>
                    <div class="imageGruppe">
                      <AppExpandableImage
                        contain
                        :src="
                          require('@/assets/zr/Gruppen_Fehler/Element7_Fehler_Ziffernrechnen.png')
                        "
                        parentId="zr"
                        elementId="zr_Gruppe_Fehler_7.png"
                      >
                      </AppExpandableImage>
                    </div>
                  </td>
                  <td>
                    Die Zahlen werden nicht korrekt stellengerecht untereinander
                    geschrieben und so die Ziffern nicht zum passenden
                    Stellenwert der Ziffern der anderen Zahl addiert.
                  </td>
                </tr>
                <tr>
                  <td>Fehler beim Übertrag</td>
                  <td>
                    <div class="imageGruppe">
                      <AppExpandableImage
                        contain
                        :src="
                          require('@/assets/zr/Gruppen_Fehler/Element2_Fehler_Ziffernrechnen.png')
                        "
                        parentId="zr"
                        elementId="zr_Gruppe_Fehler_2.png"
                      >
                      </AppExpandableImage>
                    </div>
                  </td>
                  <td>
                    Hierbei wird der Übertrag falsch vermerkt oder nicht
                    bedacht. In Zusammenhang mit dem Übertrag gibt es bei der
                    schriftlichen Addition mehrere Fehlertypen. Zum einen kann
                    der Übertrag an der falschen Stelle bzw. gar nicht
                    eingetragen oder in der Rechnung nicht mitgerechnet werden.
                    Bei Fehlern mit dem Übertrag können Probleme beim
                    verständigen Ausführen des Algorithmus Ursache sein.
                  </td>
                </tr>
                <tr>
                  <td>Fehler beim Vorgehen</td>
                  <td>
                    <div class="imageGruppe">
                      <AppExpandableImage
                        contain
                        :src="
                          require('@/assets/zr/Gruppen_Fehler/Element9_Fehler_Ziffernrechnen.png')
                        "
                        parentId="zr"
                        elementId="zr_Gruppe_Fehler_9.png"
                      >
                      </AppExpandableImage>
                    </div>
                  </td>
                  <td>
                    Hier wurde von links nach rechts gerechnet und somit das
                    Verfahren der schriftlichen Addition falsch angewendet.
                    Dadurch wurde der Übertrag an der falschen Stelle
                    eingetragen. Es wird deutlich, dass bei dieser Art von
                    Fehler der Algorithmus der schriftlichen Addition noch nicht
                    verstanden, ggf. lediglich automatisiert und falsch
                    abgerufen wurde.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </LernbausteinGruppen>
    </div>
    <p>
      Auch Beschreibungen und Begründungen von Kindern können Indizien dafür
      liefern, inwiefern ein Verständnis zum jeweiligen schriftlichen Verfahren
      aufgebaut wurde. Nachfolgend sind vier schriftliche Auszüge von Lernenden
      aufgeführt, die Sie aufsteigend danach ordnen sollen, inwiefern aus der
      Aussage auf ein Verständnis zum Übertrag geschlossen werden kann.
    </p>
    <div style="background-color: white">
      <LernbausteinSortieren
        :id="LBSTSortieren[0].id"
        :workOrder="LBSTSortieren[0].workOrder"
        :elements="LBSTSortieren[0].elements"
        :responses="LBSTSortieren[0].responses"
        :left="LBSTSortieren[0].left"
        :right="LBSTSortieren[0].right"
      >
        <template v-slot:alternative-content>
          <AppExpandableImage
            contain
            :src="require('@/assets/zr/Ordnen/01.png')"
            parentId="zr"
            elementId="zr_ordnen_01.png"
          >
          </AppExpandableImage>
          <p>
            Aus dieser Aussage kann ein besonders hohes Maß an Verständnis des
            Kindes abgeleitet werden, da hier auf zwei Ebenen deutlich wird,
            dass das Kind inhaltlich verstanden hat, was ein Übertrag bedeutet.
          </p>
          <p>
            Einerseits wird deutlich, dass das Kind auf konzeptioneller Ebene
            beschreiben kann, dass ein Übertrag in die nächsthöhere Stelle
            vorgenommen werden muss, wenn mehr als 9 Elemente an einer Stelle
            stehen – also dann in den nächsthöheren Stellenwert gebündelt werden
            muss.
          </p>
          <p>
            Andererseits werden verallgemeinernde Elemente deutlich, die anhand
            eines Beispiels begründet werden. Das Nutzen von Verallgemeinerungen
            kann nur dann gelingen, wenn das Kind inhaltlich verstanden hat, was
            es bedeutet, einen Übertrag vorzunehmen.
          </p>
          <p>
            Schauen Sie sich die Aussagen also nochmal auf Grundlage dieser
            beiden Aspekte an, die ein Verständnis aus der jeweiligen Aussage
            ableiten lassen.
          </p>
        </template>
      </LernbausteinSortieren>
    </div>
    <p></p>
    <AppLiteraturZR />
    <AppBottomNavZR
      back="/ziffernrechnen/uebergang"
      next="/ziffernrechnen/check"
    />
  </div>
</template>

<script>
import AppBottomNavZR from "@/common/AppBottomNavZR";
import AppLiteraturZR from "@/common/AppLiteraturZR";
import AppExpandableImage from "@/common/AppExpandableImage";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LBST_Gruppen_Fehler from "@/components/faledia/seiten/ziffernrechnen/LBST/LBST_Gruppen_Fehler";

import LernbausteinSortieren from "@/components/faledia/explorativeElemente/LernbausteinSortieren";
import LBST_Ordnen from "@/components/faledia/seiten/ziffernrechnen/LBST/LBST_Ordnen_Begruendungen";

export default {
  components: {
    AppBottomNavZR,
    AppLiteraturZR,
    LernbausteinGruppen,
    AppExpandableImage,
    LernbausteinSortieren,
  },
  data: () => ({
    LBSTGruppen: LBST_Gruppen_Fehler,
    LBSTSortieren: [LBST_Ordnen],
  }),
};
</script>

<style scoped>
.imageGruppe {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 150px;
}
</style>
