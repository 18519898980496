function createObject() {
  let example = {
    id: "LBST_Ordnen_Begruendungen",
    left: "wenig <br><br> Verständnis",
    right: "hohes <br><br> Verständnis",
    showGroupNames: false,
    maximalAmountElementsInGroup: 4,
    numberOfPlaceholder: 4,
    checkAfterHowManyItems: 4,
    checkAfterExactItemCount: true,
    workOrder: `
    Hier sehen Sie vier Lösungen von Kindern, die erklären sollen, was ein Übertrag bedeutet. Ziehen Sie die Aussagen auf die Linie und ordnen Sie sie diese danach, inwiefern ein Verständnis des Übertrags durch die Aussagen transportiert wird. Wenn aus der Aussage weniger Verständnis abzuleiten ist, steht diese links, kann viel Verständnis abgeleitet werden, rechts. Sobald Sie alle vier Aussagen angeordnet haben, drücke Sie auf „Überprüfen“, um eine Rückmeldung zu erhalten.
    `,
    possibleCategories: [
      { id: 1, name: "1" },
      {
        id: 2,
        name: "2",
      },
      {
        id: 3,
        name: "3",
      },
      {
        id: 4,
        name: "4",
      },
    ],
    elements: [
      {
        id: 2,
        image: require("@/assets/zr/Ordnen/03.png"),
        rank: 2,
        flex: 3,
      },
      {
        id: 4,
        image: require("@/assets/zr/Ordnen/01.png"),
        rank: 4,
        flex: 3,
      },
      {
        id: 3,
        image: require("@/assets/zr/Ordnen/02.png"),
        rank: 3,
        flex: 3,
      },
      {
        id: 1,
        image: require("@/assets/zr/Ordnen/04.png"),
        rank: 1,
        flex: 3,
      },
    ],
  };

  const richtig = "Genau, so würden wir die Aussagen auch anordnen! ";

  const fehler = "Zum Teil sehen wir das ähnlich. ";

  const error = "Das hätten wir anders angeordnet. ";

  const tippBedeutung =
    "Überlegen Sie, in welchen Aussagen deutlich wird, welche Bedeutung hinter der Übertragseins steckt und in welchen Aussagen lediglich das Ausüben der Vorgehensvorschrift deutlich wird. ";

  const tippBeispiel =
    "Schauen Sie sich auch nochmal an, in welchen Aussagen schon allgemeiner argumentiert wird und bei welchen Aussagen es rein auf Beispielebene bleibt. Verallgemeinernde Elemente deuten auf tieferes Verständnis hin. ";

  example.responses = [
    {
      id: "1234",
      text: richtig,
    },
    {
      id: "1243",
      text:
        fehler +
        "Eine Aussage würden wir allerdings an einer anderen Position einordnen. " +
        tippBeispiel,
    },
    {
      id: "1324",
      text: error + tippBedeutung,
    },
    {
      id: "1342",
      text: error + tippBedeutung,
    },
    {
      id: "1423",
      text: error + tippBedeutung,
    },
    {
      id: "1432",
      text: error + tippBedeutung,
    },
    {
      id: "2134",
      text:
        fehler +
        "Eine Aussage würden wir allerdings an einer anderen Position einordnen. " +
        tippBeispiel,
    },
    {
      id: "2143",
      text:
        fehler +
        "Sie haben bereits tendenziell diejenigen Aussagen, aus denen man ein höheres Maß an Verständnis ableiten kann eher rechts eingeordnet und aus denen weniger Verständnis abgeleitet werden kann eher links. " +
        tippBeispiel,
    },
    {
      id: "2314",
      text:
        fehler +
        "Eine Aussage würden wir allerdings an einer anderen Position einordnen. " +
        tippBeispiel,
    },
    {
      id: "2341",
      text:
        fehler +
        "Allerdings hätten wir eine Aussage an einer ganz anderen Position eingeordnet. " +
        tippBedeutung,
    },
    {
      id: "2413",
      text: error + tippBedeutung,
    },
    {
      id: "2431",
      text: error + tippBedeutung,
    },
    {
      id: "3124",
      text:
        fehler +
        "Eine Aussage würden wir allerdings an einer anderen Position einordnen. " +
        tippBeispiel,
    },
    {
      id: "3142",
      text: error + tippBedeutung,
    },
    {
      id: "3214",
      text: error + tippBedeutung,
    },
    {
      id: "3241",
      text: error + tippBedeutung,
    },
    {
      id: "3412",
      text: error + tippBedeutung + tippBeispiel,
    },
    {
      id: "3421",
      text: error + tippBedeutung + tippBeispiel,
    },
    {
      id: "4123",
      text:
        fehler +
        "Allerdings hätten wir eine Aussage an einer ganz anderen Position eingeordnet. " +
        tippBedeutung,
    },
    {
      id: "4132",
      text: error + tippBedeutung,
    },
    {
      id: "4213",
      text: error + tippBedeutung,
    },
    {
      id: "4231",
      text: error + tippBedeutung + tippBeispiel,
    },
    {
      id: "4312",
      text: error + tippBedeutung + tippBeispiel,
    },
    {
      id: "4321",
      text: error,
    },
  ];

  return example;
}

export default createObject();
